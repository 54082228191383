import locales from '~/assets/data/locales.json'

export function useStrapiLocale(epicLang: string, strapiLang: string) {
  const newLang = ref()
  if (epicLang)
    newLang.value = locales.find(lang => lang.region === epicLang)?.lang
  else if (strapiLang)
    newLang.value = locales.find(lang => lang.lang === strapiLang)?.region
  return newLang.value || 'en'
}
